import React from 'react';
import Layout, {
    bgImageDefault,
    Content,
    PageHeaderC,
} from '../components/layout';
import Seo from '../components/seo';

import { graphql } from 'gatsby';
import { PageSubtitle, PageText } from '../components/typography';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { EnhancedLink } from '../components/link';

function Imprint({ data, location }) {
    const { t } = useTranslation();
    const commonInfo = [
        {
            title: 'Unternehmen',
            value: 'condignum GmbH',
            titleId: 'companyName',
        },
        {
            title: 'Adresse',
            value: 'Prinz Eugen Straße 70/2/1, 1040 Wien, Österreich',
            titleId: 'address',
        },
        {
            title: 'E-Mail',
            value: 'office@condignum.com',
        },
        {
            title: 'Telefon',
            value: '+43 1 997 29 02',
            titleId: 'phone',
        }
    ];
    const legalInfo = [
        { title: 'Rechtsform', value: 'GmbH', titleId: 'legalForm' },
        {
            title: 'Registrierungsnummer',
            value: '504524y',
            titleId: 'registrationNumber',
        },
        {
            title: 'Zuständige Behörde',
            value: 'Handelsgericht Wien',
            titleId: 'authority',
            valueId: 'court',
        },
        { title: 'UID Nummer', value: 'ATU73961457', titleId: 'uid' },
        {
            title: 'Anzuwendendes Recht',
            value: (
                <EnhancedLink to="https://www.ris.bka.gv.at/">
                    Gewerbeordnung
                </EnhancedLink>
            ),
            titleId: 'law',
        },
        {
            title: 'Geschäftsführer',
            value: 'Simon Leitner, Martin Niederwieser',
            titleId: 'ceo',
        },
    ];
    return (
        <Layout
            location={location}
            title={t('imprint', 'Impressum')}
            headerContent={<PageHeaderC title={t('imprint', 'Impressum')} />}
            bgImage={bgImageDefault.default}
        >
            <Seo title="Impressum" />
            <Content>
                <PageText>
                    <Trans i18nKey="preText">
                        Gemäß § 25 MedienG, § 5 E-Commerce Gesetz (ECG) und § 14
                        UGB:
                    </Trans>
                </PageText>
                <PageSubtitle hasMargin={false}>
                    <Trans i18nKey="companyDetails">Unternehmensdetails</Trans>
                </PageSubtitle>
                <div className="mb-8">
                    <DataTable rows={commonInfo} />
                </div>
                <div className="mb-8">
                    <DataTable rows={legalInfo} />
                </div>

                <PageSubtitle hasMargin={false}>
                    <Trans i18nKey="companySubject">
                        Unternehmensgegenstand
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="postText1">
                        Unternehmensberatung und Dienstleistungen in der
                        automatischen Datenverarbeitung und Informationstechnik.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="postText2">
                        condignum ist eine eingetragene Marke der Condignum
                        GmbH.
                    </Trans>
                </PageText>
            </Content>
        </Layout>
    );
}

function DataTable({ rows }) {
    return (
        <table className="shadow-sm w-full border-collapse text-left border-spacing-0 border-l-2 border-l-primary">
            <tbody className="table-row-group align-middle">
                {rows.map(row => (
                    <tr className="w-full block sm:table-row" key={row.title}>
                        <td className="font-bold px-2 py-1 border border-light-grey overflow-ellipsis overflow-hidden block sm:table-cell">
                            <Trans i18nKey={row.titleId}>{row.title}</Trans>
                        </td>
                        <td className="px-2 sm:px-4 py-1 border border-light-grey overflow-ellipsis overflow-hidden block sm:table-cell">
                            <Trans i18nKey={row.valueId}>{row.value}</Trans>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Imprint;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["imprint"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
